import React from 'react';
import { Link } from 'react-router-dom';
import coding from '../coding.png';
import logo from '../assets/gifs/logo.gif';

const Logo = () => {
    return (
        <Link to='/'><img className='w-20 rounded-full border-4 border-l-rose-600 border-t-green-600 border-r-blue-600 border-b-orange-300' src={logo} alt="logo" /></Link>
    );
};

export default Logo;