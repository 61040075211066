import React, { useContext } from 'react';
import Fast from '../../../assets/icons/fast.svg';
import Intuitive from '../../../assets/icons/intuitive.svg';
import Responsive from '../../../assets/icons/responsive.svg';
import Dynamic from '../../../assets/icons/dynamic.png';
import html5 from '../../../assets/icons/html.svg';
import css3 from '../../../assets/icons/css.svg';
import javascript from '../../../assets/icons/javascript.svg';
import reactjs from '../../../assets/icons/reactjs.svg';
import nodejs from '../../../assets/icons/nodejs.svg';
import expressjs from '../../../assets/icons/expressjs.svg';
import mongodb from '../../../assets/icons/mongodb.svg';
import firebase from '../../../assets/icons/firebase.svg';
import vercel from '../../../assets/icons/vercel.svg';
import tailwindcss from '../../../assets/icons/tailwindcss.svg';
import bootstrap from '../../../assets/icons/bootstrap.svg';
import react_bootstrap from '../../../assets/icons/react_bootstrap.svg';
import daisyui from '../../../assets/icons/daisyui.png';
import flowbite from '../../../assets/icons/flowbite.svg';
import figma from '../../../assets/icons/figma.svg';
import github from '../../../assets/icons/github.svg';
import netlify from '../../../assets/icons/netlify.svg';
import vscode from '../../../assets/icons/VS_Code.svg.png';
import { AuthContext } from '../../../context/AuthProvider';
import PriorityItem from '../../../components/PriorityItem';

const IntroSection = () => {
    const { isDark } = useContext(AuthContext);
    const icons = [html5, css3, javascript, reactjs, nodejs, expressjs, mongodb, firebase, vercel, tailwindcss, daisyui, flowbite, bootstrap, react_bootstrap, figma, netlify, github, vscode];
    const priorityList = [{ name: 'Fast', icon: Fast }, { name: 'Responsive', icon: Responsive }, { name: 'Dynamic', icon: Dynamic }, { name: 'Intuitive', icon: Intuitive }];

    return (
        <div className={`order-2 lg:order-1 py-5 lg:py-2 flex flex-col items-center justify-center ${isDark ? 'bg-slate-800 text-white' : 'bg-white'}`}>
            <h4 className='leading-relaxed text-xl font-semibold'>Hi! I'm Tazrian.</h4>
            <p className='text-xl leading-relaxed mt-2'>
                I'm a <span className='font-semibold'>React JS Developer</span><br />
                coding your ideas<br />
                to turn them into reality! <br />
            </p>

            <div className='w-4/5'>
                <p className='font-semibold text-xl leading-loose pb-1 pt-2'>My priority is to make websites</p>
                <div className='flex items-center justify-between md:justify-around'>
                    {
                        priorityList.map((item, idx) => <PriorityItem
                            key={idx}
                            item={item}
                        ></PriorityItem>)
                    }
                </div>
            </div>

            <div>
                <h4 className='leading-loose text-xl font-semibold pt-2'>Technologies I'm working with</h4>
                <div className={`flex flex-wrap items-center justify-center w-4/5 mx-auto mt-2 rounded-lg ${isDark && 'bg-white'}`}>
                    {
                        icons.map((icon, idx) => <img
                            key={idx}
                            className='w-10 m-2'
                            src={icon}
                            alt="" />)
                    }
                </div>
            </div>
        </div>
    );
};

export default IntroSection;