import React, { useContext } from 'react';
import useTitle from '../../hooks/useTitle';
import img1_1 from '../../assets/images/project_1/img1_1.png';
import img1_2 from '../../assets/images/project_1/img1_2.png';
import img1_3 from '../../assets/images/project_1/img1_3.png';
import img1_4 from '../../assets/images/project_1/img1_4.png';
import img1_5 from '../../assets/images/project_1/img1_5.png';
import img1_6 from '../../assets/images/project_1/img1_6.png';
import img1_7 from '../../assets/images/project_1/img1_7.png';
import img1_8 from '../../assets/images/project_1/img1_8.png';

import img2_1 from '../../assets/images/project_2/img2_1.png';
import img2_2 from '../../assets/images/project_2/img2_2.png';
import img2_3 from '../../assets/images/project_2/img2_3.png';
import img2_4 from '../../assets/images/project_2/img2_4.png';
import img2_5 from '../../assets/images/project_2/img2_5.png';
import img2_6 from '../../assets/images/project_2/img2_6.png';
import img2_7 from '../../assets/images/project_2/img2_7.png';

import img3_1 from '../../assets/images/project_3/img3_1.png';
import img3_2 from '../../assets/images/project_3/img3_2.png';
import img3_3 from '../../assets/images/project_3/img3_3.png';
import img3_4 from '../../assets/images/project_3/img3_4.png';
import img3_5 from '../../assets/images/project_3/img3_5.png';
import img3_6 from '../../assets/images/project_3/img3_6.png';
import img3_7 from '../../assets/images/project_3/img3_7.png';
import img3_8 from '../../assets/images/project_3/img3_8.png';

import github from '../../assets/icons/github.svg';
import live from '../../assets/icons/live.svg';
import { Tabs } from 'flowbite-react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../context/AuthProvider';

const Projects = () => {

    useTitle('Projects of');
    const { isDark } = useContext(AuthContext);

    return (
        <div className={`lg:w-3/4 mx-auto rounded-none ${isDark ? 'bg-slate-900 text-white' : 'bg-white'}`}>
            <Tabs.Group
                aria-label="Tabs with underline"
                // eslint-disable-next-line react/style-prop-object
                style="underline"
            >
                <Tabs.Item active={true} title="Project 1">
                    <div className="carousel w-full">
                        <div id="item1_1" className="carousel-item w-full">
                            <img src={img1_1} alt='' className="w-full" />
                        </div>
                        <div id="item1_2" className="carousel-item w-full">
                            <img src={img1_2} alt='' className="w-full" />
                        </div>
                        <div id="item1_3" className="carousel-item w-full">
                            <img src={img1_3} alt='' className="w-full" />
                        </div>
                        <div id="item1_4" className="carousel-item w-full">
                            <img src={img1_4} alt='' className="w-full" />
                        </div>
                        <div id="item1_5" className="carousel-item w-full">
                            <img src={img1_5} alt='' className="w-full" />
                        </div>
                        <div id="item1_6" className="carousel-item w-full">
                            <img src={img1_6} alt='' className="w-full" />
                        </div>
                        <div id="item1_7" className="carousel-item w-full">
                            <img src={img1_7} alt='' className="w-full" />
                        </div>
                        <div id="item1_8" className="carousel-item w-full">
                            <img src={img1_8} alt='' className="w-full" />
                        </div>
                    </div>
                    <div className="flex justify-center w-full py-2 gap-2">
                        <a href="#item1_1" className="btn btn-xs">1</a>
                        <a href="#item1_2" className="btn btn-xs">2</a>
                        <a href="#item1_3" className="btn btn-xs">3</a>
                        <a href="#item1_4" className="btn btn-xs">4</a>
                        <a href="#item1_5" className="btn btn-xs">5</a>
                        <a href="#item1_6" className="btn btn-xs">6</a>
                        <a href="#item1_7" className="btn btn-xs">7</a>
                        <a href="#item1_8" className="btn btn-xs">8</a>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-5 text-left">
                        <div className="border p-5">
                            <h3 className='text-lg text-center font-semibold leading-loose'>Project Summary</h3>
                            <ul class="list-inside list-disc leading-loose">
                                <li>A responsive website for buying & selling houses. One can buy and sell their house by category upon registering.</li>
                                <li>Used MERN stack to make it a complete website. Implemented email & social authentication, JSON Web Token, tailwind CSS, daisyUI, private routes, context API, and more. Deployed code in firebase.</li>
                                <li>Used Express to create server application & MongoDB to handle database. Implemented basic CRUD operation. Added middleware to avoid CORS policy error and share data with the client side. Deployed code in Vercel & used Environment Variables.</li>
                                {/* <li>Still working on this project to develop it further.</li> */}
                            </ul>
                        </div>
                        <div className="border p-5">
                            <h3 className='text-lg text-center font-semibold leading-loose'>Key Technologies</h3>
                            <ul class="leading-loose">
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> React JS</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> React Router</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Tailwind CSS</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> MongoDB</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Node JS</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Express</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Firebase</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Vercel</li>
                            </ul>
                            <h3 className='text-lg text-center font-semibold leading-loose mt-5'>View</h3>
                            <div>
                                <div className="m-2 rounded-full p-3 flex items-center justify-evenly border">
                                    <div>
                                        <a className='flex flex-col items-center justify-center' href="https://github.com/tazrian-amin/Real_Estate_Broker" target='_blank' rel="noreferrer">
                                            <img className={`w-8 ${isDark && 'bg-white rounded-full'}`} src={github} alt="" />
                                            <p>Client</p>
                                        </a>
                                    </div>
                                    <div>
                                        <a className='flex flex-col items-center justify-center' href="https://github.com/tazrian-amin/Real_Estate_Server" target='_blank' rel="noreferrer">
                                            <img className={`w-8 ${isDark && 'bg-white rounded-full'}`} src={github} alt="" />
                                            <p>Server</p>
                                        </a>
                                    </div>
                                    <div>
                                        <a className='flex flex-col items-center justify-center' href="https://real-estate-broker-2fb26.web.app/" target='_blank' rel="noreferrer">
                                            <img className={`w-8 ${isDark && 'bg-white rounded-full'}`} src={live} alt="" />
                                            <p>Live</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tabs.Item>
                <Tabs.Item active={true} title="Project 2">
                    <div className="carousel w-full">
                        <div id="item1" className="carousel-item w-full">
                            <img src={img2_1} alt='' className="w-full" />
                        </div>
                        <div id="item2" className="carousel-item w-full">
                            <img src={img2_2} alt='' className="w-full" />
                        </div>
                        <div id="item3" className="carousel-item w-full">
                            <img src={img2_3} alt='' className="w-full" />
                        </div>
                        <div id="item4" className="carousel-item w-full">
                            <img src={img2_4} alt='' className="w-full" />
                        </div>
                        <div id="item5" className="carousel-item w-full">
                            <img src={img2_5} alt='' className="w-full" />
                        </div>
                        <div id="item6" className="carousel-item w-full">
                            <img src={img2_6} alt='' className="w-full" />
                        </div>
                        <div id="item7" className="carousel-item w-full">
                            <img src={img2_7} alt='' className="w-full" />
                        </div>
                    </div>
                    <div className="flex justify-center w-full py-2 gap-2">
                        <a href="#item1" className="btn btn-xs">1</a>
                        <a href="#item2" className="btn btn-xs">2</a>
                        <a href="#item3" className="btn btn-xs">3</a>
                        <a href="#item4" className="btn btn-xs">4</a>
                        <a href="#item5" className="btn btn-xs">5</a>
                        <a href="#item6" className="btn btn-xs">6</a>
                        <a href="#item7" className="btn btn-xs">7</a>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-5 text-left">
                        <div className="p-5 border">
                            <h3 className='text-lg text-center font-semibold leading-loose'>Project Summary</h3>
                            <ul class="list-inside list-disc leading-loose">
                                <li>A responsive website for buying & selling used phones. One can buy & sell their phones by category upon registering as a buyer and seller respectively. Buyer, seller, and admin dashboards were added too.</li>
                                <li>Used MERN stack to make it a complete website. Implemented email & social authentication, JSON web token for authorization, react hook form to collect user data, private routes, context API, and more. Deployed code in firebase.</li>
                                <li>Used Express for server application & MongoDB for database management. Implemented basic CRUD operation. Added middleware to avoid CORS policy error and share data with the client side. Deployed code in Vercel & used Environment Variables.</li>
                            </ul>
                        </div>
                        <div className="p-5 border">
                            <h3 className='text-lg text-center font-semibold leading-loose'>Key Technologies</h3>
                            <ul class="leading-loose">
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> React JS</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> React Router</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Tailwind CSS</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> MongoDB</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Node JS</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Express</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Firebase</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Vercel</li>
                            </ul>
                            <h3 className='text-lg text-center font-semibold leading-loose mt-5'>View</h3>
                            <div>
                                <div className="m-2 rounded-full p-3 flex items-center justify-evenly border">
                                    <div>
                                        <a className='flex flex-col items-center justify-center' href="https://github.com/tazrian-amin/ABA_Recycle_Phone" target='_blank' rel="noreferrer">
                                            <img className={`w-8 ${isDark && 'bg-white rounded-full'}`} src={github} alt="" />
                                            <p>Client</p>
                                        </a>
                                    </div>
                                    <div>
                                        <a className='flex flex-col items-center justify-center' href="https://github.com/tazrian-amin/Recycle_Phone_Server" target='_blank' rel="noreferrer">
                                            <img className={`w-8 ${isDark && 'bg-white rounded-full'}`} src={github} alt="" />
                                            <p>Server</p>
                                        </a>
                                    </div>
                                    <div>
                                        <a className='flex flex-col items-center justify-center' href="https://aba-recycle.web.app/" target='_blank' rel="noreferrer">
                                            <img className={`w-8 ${isDark && 'bg-white rounded-full'}`} src={live} alt="" />
                                            <p>Live</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tabs.Item>
                <Tabs.Item active={true} title="Project 3">
                    <div className="carousel w-full">
                        <div id="item3_1" className="carousel-item w-full">
                            <img src={img3_1} alt='' className="w-full" />
                        </div>
                        <div id="item3_2" className="carousel-item w-full">
                            <img src={img3_2} alt='' className="w-full" />
                        </div>
                        <div id="item3_3" className="carousel-item w-full">
                            <img src={img3_3} alt='' className="w-full" />
                        </div>
                        <div id="item3_4" className="carousel-item w-full">
                            <img src={img3_4} alt='' className="w-full" />
                        </div>
                        <div id="item3_5" className="carousel-item w-full">
                            <img src={img3_5} alt='' className="w-full" />
                        </div>
                        <div id="item3_6" className="carousel-item w-full">
                            <img src={img3_6} alt='' className="w-full" />
                        </div>
                        <div id="item3_7" className="carousel-item w-full">
                            <img src={img3_7} alt='' className="w-full" />
                        </div>
                        <div id="item3_8" className="carousel-item w-full">
                            <img src={img3_8} alt='' className="w-full" />
                        </div>
                    </div>
                    <div className="flex justify-center w-full py-2 gap-2">
                        <a href="#item3_1" className="btn btn-xs">1</a>
                        <a href="#item3_2" className="btn btn-xs">2</a>
                        <a href="#item3_3" className="btn btn-xs">3</a>
                        <a href="#item3_4" className="btn btn-xs">4</a>
                        <a href="#item3_5" className="btn btn-xs">5</a>
                        <a href="#item3_6" className="btn btn-xs">6</a>
                        <a href="#item3_7" className="btn btn-xs">7</a>
                        <a href="#item3_8" className="btn btn-xs">8</a>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-5 text-left">
                        <div className="border p-5">
                            <h3 className='text-lg text-center font-semibold leading-loose'>Project Summary</h3>
                            <ul class="list-inside list-disc leading-loose">
                                <li>A responsive website for different courses. One can look up courses by category, see and download PDFs of course details, enroll via registration by Email, Google, or GitHub, and more.</li>
                                <li>Used React & React Router to make it a single-page application. Implemented firebase authentication with private routes, react-bootstrap, conditional rendering, context API, and more. Deployed code in firebase.</li>
                                <li>Used Express for server application. Added fake data and cors-middleware to share data with the client side. Used GET HTTP method for retrieving data and sending it to the client side. Deployed code in Vercel.</li>
                                {/* <li>Still working on this project to develop it further.</li> */}
                            </ul>
                        </div>
                        <div className="border p-5">
                            <h3 className='text-lg text-center font-semibold leading-loose'>Key Technologies</h3>
                            <ul class="leading-loose">
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> React JS</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> React Router</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> React-Bootstrap</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Node JS</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Express</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Firebase</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} className='mr-2' /> Vercel</li>
                            </ul>
                            <h3 className='text-lg text-center font-semibold leading-loose mt-5'>View</h3>
                            <div>
                                <div className="m-2 rounded-full p-3 flex items-center justify-evenly border">
                                    <div>
                                        <a className='flex flex-col items-center justify-center' href="https://github.com/tazrian-amin/Course_Hub" target='_blank' rel="noreferrer">
                                            <img className={`w-8 ${isDark && 'bg-white rounded-full'}`} src={github} alt="" />
                                            <p>Client</p>
                                        </a>
                                    </div>
                                    <div>
                                        <a className='flex flex-col items-center justify-center' href="https://github.com/tazrian-amin/Course_Hub_Server" target='_blank' rel="noreferrer">
                                            <img className={`w-8 ${isDark && 'bg-white rounded-full'}`} src={github} alt="" />
                                            <p>Server</p>
                                        </a>
                                    </div>
                                    <div>
                                        <a className='flex flex-col items-center justify-center' href="https://learning-platform-server.web.app/" target='_blank' rel="noreferrer">
                                            <img className={`w-8 ${isDark && 'bg-white rounded-full'}`} src={live} alt="" />
                                            <p>Live</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tabs.Item>
            </Tabs.Group>

        </div>
    );
};

export default Projects;