import { useContext } from 'react';
import { Toaster } from 'react-hot-toast';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { AuthContext } from './context/AuthProvider';
import router from './router/router';

function App() {
  const { isDark } = useContext(AuthContext);
  return (
    <div className={`App ${isDark ? 'bg-slate-800' : 'bg-white'}`}>
      <RouterProvider router={router}></RouterProvider>
      <Toaster></Toaster>
    </div>
  );
}

export default App;
