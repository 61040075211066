import React, { useContext } from 'react';
// import { AiFillGithub } from 'react-icons/ai';
import { AuthContext } from '../../../context/AuthProvider';
import AboutOverview from './AboutOverview';
import CarouselSection from './CarouselSection';
import facebook from '../../../assets/icons/facebook.svg';
import linkedin from '../../../assets/icons/linkedin.svg';
import gmail from '../../../assets/icons/gmail.svg';
import whatsapp from '../../../assets/icons/whatsapp.svg';
import { Link } from 'react-router-dom';

const OverviewSection = () => {
    const { isDark } = useContext(AuthContext);

    return (
        <div className={`order-3 flex flex-col items-center justify-center ${isDark ? 'bg-slate-800 text-white' : 'bg-white'}`}>
            <h1 className='text-green-500 text-xl font-bold mb-1'>Building things for the web</h1>
            <CarouselSection></CarouselSection>

            <h1 className='text-blue-600 text-xl font-bold my-2'>Miles to go before I sleep</h1>
            <AboutOverview></AboutOverview>

            <h1 className='text-orange-400 text-xl font-bold my-1'>Let's talk business</h1>
            <div className='flex items-center justify-center w-4/5 my-2'>
                <a href="mailto:amin.tazrian1979@gmail.com" target='_blank' rel="noreferrer" title='Send an Email'><img src={gmail} alt="" /></a>
                <a href='https://www.facebook.com/profile.php?id=100076012728824' target='_blank' rel="noreferrer" title='View Facebook Profile'><img className='mx-5' src={facebook} alt="" /></a>
                <a href='https://www.linkedin.com/in/tazrian1979/' target='_blank' rel="noreferrer" title='View LinkedIn Profile'><img src={linkedin} alt="" /></a>
                <a href='https://ibb.co/xscYY9q' target='_blank' rel="noreferrer" title='Connect to WhatsApp'><img className='ml-5' src={whatsapp} alt="" /></a>
            </div>

            <h1 className='text-rose-600 text-xl font-bold my-1'>Thoughts to share</h1>
            <div className='w-3/4 my-2 border'>
                <h4 className='font-semibold'>Finding a way back from hell?</h4>
                <Link to='/portfolio/blog'><button className="btn btn-xs rounded-md border-none my-2 bg-rose-600 hover:bg-rose-700">Read full post</button></Link>
            </div>
        </div>
    );
};

export default OverviewSection;