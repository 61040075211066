import React from 'react';
import { Link } from 'react-router-dom';
import notFound from '../../assets/gifs/notFound.gif';

const NotFound = () => {
    return (
        <div className='min-h-screen flex flex-col items-center justify-center'>
            <Link className='underline' to='/'>Back to home</Link>
            <img src={notFound} alt="" />
        </div>
    );
};

export default NotFound;