import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isDark, setIsDark] = useState(false);
    const handleDarkMode = () => {
        return setIsDark(!isDark);
    };
    const authInfo = {
        isDark,
        handleDarkMode
    }
    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;