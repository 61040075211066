import useTitle from '../../hooks/useTitle';
import Summary from './Summary/Summary';
import IntroSection from './IntroSection/IntroSection';
import OverviewSection from './OverviewSection/OverviewSection';

const Home = () => {
    useTitle('');

    return (
        <div className='grid grid-cols-1 lg:grid-cols-3 min-h-screen'>
            <IntroSection></IntroSection>
            <Summary></Summary>
            <OverviewSection></OverviewSection>
        </div>
    );
};

export default Home;