import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import useTitle from '../../hooks/useTitle';

const Blog = () => {
    useTitle('Blog of');
    const { isDark } = useContext(AuthContext);
    return (
        <div className={`lg:w-3/4 mx-auto rounded-none ${isDark ? 'bg-slate-900 text-white' : 'bg-white'}`}>
            <div className='w-full text-left p-10'>
                <h4 className='text-4xl lg:text-5xl font-bold'>Finding a way back from hell?</h4>
                <p className="text-lg font-semibold pt-5">- Posted on 26 December, 2022</p>
                <p className="py-5">Are you a beginner coder who feels like you are stuck in a never-ending cycle of following tutorials without really understanding the concepts? If so, you may be in what is known as tutorial hell.</p>
                <p>Tutorial hell is a common issue faced by many people learning to code or use new software. It can be frustrating to feel like you are stuck in a loop of copying code from tutorials without truly understanding how it works. In most cases, beginners get trapped in this hell. Why? Well, many of us start our journey in coding by watching video tutorials on the web or taking a course. We learn and code whatever we see, but often we try to do only what we are shown and avoid researching theories and documentation on our own. As a result, we become reliant on copying code and unable to tackle new challenges or customized designs.</p>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
                    <div className='m-auto'>
                        <p className="pt-5">So how do you escape tutorial hell? Follow these tips:</p>
                        <ul class="list-inside list-disc leading-loose text-justify">
                            <li>Pause and think about what you want to build. Before you start watching a tutorial or working on a new project, take some time to consider what you want to achieve. This will help you focus your learning and give you a clear goal to work towards.</li>
                            <li>Ask questions and try to understand the process. Don't be afraid to ask for clarification or seek out additional information if you don't fully understand something. The more you understand the underlying concepts, the easier it will be to apply what you have learned to new situations.</li>
                            <li>Practice coding without relying on tutorials. Once you have a good understanding of the basics, try to tackle similar problems or projects on your own. This will help you build your skills and confidence in your abilities.</li>
                            <li>Research and learn new concepts on your own. If you get stuck, don't be afraid to seek out additional resources or ask for help. There are many online communities of friendly and knowledgeable people who are happy to assist.</li>
                            <li>Keep practicing. The more you practice, the better you will become at coding. So don't get discouraged if you make mistakes or struggle at first. With time and effort, you will improve.</li>
                        </ul>
                    </div>
                    <img src="https://images.unsplash.com/photo-1553559707-0d8e571f58e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80" alt="" className="m-auto w-auto lg:block hidden" />
                </div>
                <p className="pt-5">If you are feeling stuck in tutorial hell, don't worry! By following these tips and being proactive in your learning, you can escape and continue to grow and develop as a coder. Remember to take breaks and seek out support when you need it, and don't be afraid to ask for help. With patience and persistence, you can succeed.</p>
            </div>
        </div>
    );
};

export default Blog;