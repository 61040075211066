import React from 'react';
import { faBriefcase, faMapLocationDot, faSchool, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'flowbite-react';
import certificate from '../../../assets/icons/certificate.svg';

const AboutOverview = () => {
    return (
        <div className='flex items-center justify-evenly w-4/5 my-2 cursor-pointer'>
            <Tooltip
                content="Brahmanbaria, Bangladesh"
                trigger="click"
                animation="duration-500"
            >
                <FontAwesomeIcon icon={faMapLocationDot} className='text-3xl' title='click to view current location'></FontAwesomeIcon>
                <h4 className='mt-1'>Location</h4>
            </Tooltip>

            <Tooltip
                content="HSC (Science), Sylhet Cadet College, Sylhet"
                trigger="click"
                animation="duration-500"
            >
                <FontAwesomeIcon icon={faSchool} className='text-3xl mx-5' title='click to view college name' />
                <h4 className='mt-1'>College</h4>
            </Tooltip>

            <Tooltip
                content="Bachelor of Science in Computer Science, University of the People, USA"
                trigger="click"
                animation="duration-500"
            >
                <FontAwesomeIcon icon={faUniversity} className='text-3xl' title='click to view university name' />
                <h4 className='mt-1'>University</h4>
            </Tooltip>

            <Tooltip
                content="Complete Web Development Course With Jhankar Mahbub, Programming Hero"
                trigger="click"
                animation="duration-500"
            >
                <div className='mx-3 flex flex-col items-center justify-center' title='click to view courses'>
                    <img className='w-9' src={certificate} alt="" />
                    <h4>Course</h4>
                </div>
            </Tooltip>

            <Tooltip
                content="Looking for a job now!"
                trigger="click"
                animation="duration-500"
            >
                <FontAwesomeIcon icon={faBriefcase} className='text-3xl' title='click to view current job' />
                <h4 className='mt-1'>Job</h4>
            </Tooltip>
        </div>
    );
};

export default AboutOverview;