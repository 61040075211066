import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthProvider';

const PriorityItem = ({ item }) => {
    const { isDark } = useContext(AuthContext);
    return (
        <div className='flex flex-col items-center justify-center'>
            <div className={`m-2 rounded-full p-3 ${isDark && 'bg-white'}`}>
                <img className='w-8' src={item.icon} alt="" />
            </div>
            <p className='text-sm font-semibold'>{item.name}</p>
        </div>
    );
};

export default PriorityItem;