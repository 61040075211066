import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthProvider';
import coding from '../../../coding.png';
import logo from '../../../assets/gifs/logo.gif'
import bulb from '../../../assets/icons/bulb.svg';
import resume from '../../../assets/icons/resume.png';
import github from '../../../assets/icons/github.svg';

const Summary = () => {
    const { isDark, handleDarkMode } = useContext(AuthContext);

    return (
        <div className={`order-1 lg:order-2 relative flex flex-col items-center justify-center ${isDark ? 'bg-slate-800 text-white' : 'bg-white'}`}>
            <div className='flex flex-col justify-center items-center px-5 mt-20 lg:mt-0 lg:px-0'>
                <span className="indicator-item absolute -top-6"><button title={`${isDark ? 'Turn on' : 'Turn off'}`} className={`rotate-180 w-28 h-20 rounded-t-full ${!isDark && 'bg-gradient-to-t from-white via-yellow-50 to-yellow-200'}`} onClick={handleDarkMode}><img className='mx-auto' src={bulb} alt='' /></button></span>
                <Link to='/portfolio/projects'>
                    <button className="mb-4 btn btn-sm rounded-md border-none text-white bg-green-600 hover:bg-green-700">Projects</button>
                </Link>
                <div className='w-3/5 md:w-1/2 lg:w-1/2 flex items-center justify-center'>
                    <Link to='/portfolio/blog'>
                        <button className="mr-2 md:mr-4 btn btn-sm rounded-md border-none text-white bg-rose-600 hover:bg-rose-700">Blog</button>
                    </Link>
                    <img className='rounded-full border-4 border-l-rose-600 border-t-green-600 border-r-blue-600 border-b-orange-300' src={logo} alt="logo" />
                    <Link to='/portfolio/about'>
                        <button className="ml-2 md:ml-4 btn btn-sm rounded-md border-none text-white bg-blue-600 hover:bg-blue-700">About</button>
                    </Link>
                </div>
                <Link to='/portfolio/contact'>
                    <button className="mt-4 btn btn-sm rounded-md border-none text-white bg-orange-400 hover:bg-orange-500">Contact</button>
                </Link>
            </div>

            <div className='pt-5'>
                <h4 className='font-semibold'>| Available for work |</h4>
                <div className='flex items-center justify-center'>
                    <a href='https://github.com/tazrian-amin' title='View GitHub Profile' target='_blank rel="noreferrer"'><img className='w-10 mt-2' src={github} alt="" /></a>
                    <a href="https://drive.google.com/file/d/1ci6JlT3AYh74gKFKSl-Q2mR5gusp2kkT/view?usp=share_link" title='Download Resume' target='_blank' rel="noreferrer"><img className='w-10 mt-2' src={resume} alt="" /></a>
                </div>
            </div>
        </div>
    );
};

export default Summary;