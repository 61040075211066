import React, { useContext, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { AuthContext } from '../../context/AuthProvider';
import { toast } from 'react-hot-toast';

const Form = () => {
    const { isDark } = useContext(AuthContext);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const contactForm = e.target;

        emailjs.sendForm('service_p1bk8ii', 'template_dbumvkm', form.current, 'VIrbgcyZCFzpQugxY')
            .then((result) => {
                console.log(result.text);
                toast.success('Your message was delivered successfully!');
                contactForm.reset();
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <form ref={form} onSubmit={sendEmail} className={`${isDark && 'text-black'}`}>
            <div className="form-control w-full">
                <label className="label">
                    <span className={`label-text ${isDark && 'text-white'}`}>Name</span>
                </label>
                <input type="text" name="user_name" placeholder="Type your full name" className="input input-bordered w-full" />
            </div>
            <div className="form-control w-full">
                <label className="label">
                    <span className={`label-text ${isDark && 'text-white'}`}>Email</span>
                </label>
                <input type="text" name="user_email" placeholder="Type your email address" className="input input-bordered w-full" />
            </div>
            <div className="form-control">
                <label className="label">
                    <span className={`label-text ${isDark && 'text-white'}`}>Message</span>
                </label>
                <textarea className="textarea textarea-bordered h-24" name="message" placeholder="Type your message"></textarea>
            </div>
            <input type="submit" value="Send" className="btn btn-outline btn-warning my-5" />
        </form>
    );
};

export default Form;