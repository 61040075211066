import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import coding from '../../coding.png';
import logo from '../../assets/gifs/logo.gif'
import resume from '../../assets/icons/resume.png';
import bulb from '../../assets/icons/bulb.svg';
import { AuthContext } from '../../context/AuthProvider';

const Navbar = () => {
    const { isDark, handleDarkMode } = useContext(AuthContext);

    return (
        <div className={`flex items-center justify-between px-5 py-3 ${isDark ? 'bg-slate-800' : 'bg-white'}`}>
            <div className='flex items-center justify-between'>
                <div className="dropdown mr-5 lg:hidden">
                    <label tabIndex={0} className={`cursor-pointer ${isDark ? 'text-white' : 'text-slate-800'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                    </label>

                    <ul htmlFor='menu' tabIndex={0} className={`menu menu-compact dropdown-content mt-8 p-2 shadow rounded-box w-52 ${isDark ? 'bg-slate-800' : 'bg-white'}`}>
                        <li><Link to='/portfolio/projects'><button className="btn btn-sm w-full text-white rounded-md border-none bg-green-600 hover:bg-green-700">Projects</button></Link></li>
                        <li><Link to='/portfolio/about'><button className="btn btn-sm w-full text-white rounded-md border-none bg-blue-600 hover:bg-blue-700">About</button></Link></li>
                        <li><Link to='/portfolio/contact'><button className="btn btn-sm w-full text-white rounded-md border-none bg-orange-400 hover:bg-orange-500">Contact</button></Link></li>
                        <li><Link to='/portfolio/blog'><button className="btn btn-sm w-full text-white rounded-md border-none bg-rose-600 hover:bg-rose-700">Blog</button></Link></li>
                    </ul>
                </div>
                <Link to='/'><img className='w-20 rounded-full border-4 border-l-rose-600 border-t-green-600 border-r-blue-600 border-b-orange-300' src={logo} alt="logo" /></Link>
            </div>

            <div className='hidden lg:flex lg:items-center lg:justify-center'>
                <Link to='/portfolio/projects'><button className="btn btn-md rounded-md border-none text-white bg-green-600 hover:bg-green-700">Projects</button></Link>
                <Link to='/portfolio/about'><button className="btn btn-md mx-5 rounded-md border-none text-white bg-blue-600 hover:bg-blue-700">About</button></Link>
                <Link to='/portfolio/contact'><button className="btn btn-md rounded-md border-none text-white bg-orange-400 hover:bg-orange-500">Contact</button></Link>
                <Link to='/portfolio/blog'><button className="btn btn-md ml-5 rounded-md border-none text-white bg-rose-600 hover:bg-rose-700">Blog</button></Link>
            </div>

            <div className='flex items-center justify-between relative'>
                <a href="https://drive.google.com/file/d/1ci6JlT3AYh74gKFKSl-Q2mR5gusp2kkT/view?usp=share_link" title='Download Resume' target='_blank' rel="noreferrer"><img className='w-10 mr-12' src={resume} alt="" /></a>
                <button className={`w-12 absolute -right-5 h-20 rounded-l-full ${!isDark && 'bg-gradient-to-l from-white via-yellow-50 to-yellow-200'}`} onClick={handleDarkMode} title={`${isDark ? 'Turn on' : 'Turn off'}`}><img className={`-rotate-90 absolute right-2 top-6 ${!isDark && 'text-yellow-400'}`} src={bulb} alt="" /></button>
            </div>
        </div>
    );
};

export default Navbar;