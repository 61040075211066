import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import useTitle from '../../hooks/useTitle';
import photo from '../../assets/images/photo.png';

const About = () => {
    useTitle('About');
    const { isDark } = useContext(AuthContext);
    return (
        <div className={`lg:w-3/4 mx-auto rounded-none ${isDark ? 'bg-slate-900 text-white' : 'bg-white'}`}>
            <div className="hero">
                <div className="hero-content items-center justify-center flex-col md:flex-row">
                    <img src={photo} alt="" className="md:w-1/2" />
                    <div className='md:text-left'>
                        <h1 className="text-4xl lg:text-5xl font-bold py-6">Tazrian Ibne Amin</h1>
                        <p>As a full-stack web developer with a strong passion for web application development, I am currently pursuing a Bachelor of Science in Computer Science from the University of the People in the United States.</p>
                        <p className="py-6">Prior to my current studies, I received a 50% merit-based tuition waiver for my Bachelor of Business Administration at North South University. After completing 27 credits with a CGPA of 3.62, I realized my true passion for computer science, particularly web development. I have since furthered my skills in this field through a bootcamp with Programming Hero.</p>
                        <p>I have a solid foundation of knowledge and skills in web development and have consistently demonstrated the ability to work effectively and am ready to be a part of a dynamic team. In my previous bootcamp, I have proven myself to be a reliable and motivated individual, and I am confident in my ability to make a positive contribution to any organization.</p>
                        <p className="py-6">Throughout my journey, I have faced challenges and learned from them, helping me grow as a developer and an individual. I am a driven and committed professional with a passion for continuous learning and personal growth. I am eager to take on new challenges and make a meaningful impact in the field of web development.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;