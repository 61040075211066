import About from "../pages/About/About";
import Blog from "../pages/Blog/Blog";
import Contact from "../pages/Contact/Contact";
import NotFound from "../pages/NotFound/NotFound";
import Projects from "../pages/Projects/Projects";

const { createBrowserRouter } = require("react-router-dom");
const { default: Main } = require("../layout/Main/Main");
const { default: Home } = require("../pages/Home/Home");

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home></Home>
    },
    {
        path: '/portfolio',
        element: <Main></Main>,
        children: [
            {
                path: '/portfolio/projects',
                element: <Projects></Projects>
            },
            {
                path: '/portfolio/about',
                element: <About></About>
            },
            {
                path: '/portfolio/contact',
                element: <Contact></Contact>
            },
            {
                path: '/portfolio/blog',
                element: <Blog></Blog>
            }
        ]
    },
    {
        path: '*',
        element: <NotFound></NotFound>
    }
])

export default router;