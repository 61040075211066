import React from 'react';
import { Carousel } from 'flowbite-react';
import project_1 from '../../../assets/images/project-1.png';
import project_2 from '../../../assets/images/project-2.png';
import project_3 from '../../../assets/images/project-3.png';
import project_4 from '../../../assets/images/project-4.png';

const CarouselSection = () => {
    return (
        <div className="w-4/5 h-48 md:h-96 lg:w-3/5 lg:h-1/4 rounded-lg my-2">
            <Carousel indicators={false}>
                <img className='w-full p-2 rounded-lg' src={project_1} alt="" />
                <img className='w-full p-2 rounded-lg' src={project_2} alt="" />
                <img className='w-full p-2 rounded-lg' src={project_3} alt="" />
                <img className='w-full p-2 rounded-lg' src={project_4} alt="" />
            </Carousel>
        </div>
    );
};

export default CarouselSection;