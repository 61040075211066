import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import useTitle from '../../hooks/useTitle';
import gmail from '../../assets/icons/gmail.svg';
import facebook from '../../assets/icons/facebook.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import whatsapp from '../../assets/icons/whatsapp.svg';
import Form from './Form';

const Contact = () => {
    useTitle('Contact');
    const { isDark } = useContext(AuthContext);
    return (
        <div className={`lg:w-3/4 mx-auto rounded-none px-10 flex flex-col items-center ${isDark ? 'bg-slate-900 text-white' : 'bg-white'}`}>
            <h1 className='text-3xl font-bold py-5'>Let's talk business</h1>
            <div className='flex items-center justify-center py-5'>
                <a href="mailto:amin.tazrian1979@gmail.com" target='_blank' rel="noreferrer" title='Send an Email'><img src={gmail} alt="" /></a>
                <a href='https://www.facebook.com/profile.php?id=100076012728824' target='_blank' rel="noreferrer" title='View Facebook Profile'><img className='mx-5' src={facebook} alt="" /></a>
                <a href='https://www.linkedin.com/in/tazrian1979/' target='_blank' rel="noreferrer" title='View LinkedIn Profile'><img src={linkedin} alt="" /></a>
                <a href='https://ibb.co/xscYY9q' target='_blank' rel="noreferrer" title='Connect to WhatsApp'><img className='ml-5' src={whatsapp} alt="" /></a>
            </div>
            <div>
                <h1 className='text-3xl font-bold py-5'>Send a message right away!</h1>
                <Form></Form>
            </div>

        </div>
    );
};

export default Contact;