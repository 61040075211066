import React, { useContext } from 'react';
import Logo from '../../../components/Logo';
import { AuthContext } from '../../../context/AuthProvider';

const Footer = () => {
    const { isDark } = useContext(AuthContext);
    return (
        <div className={`flex items-center justify-center py-5 ${isDark ? 'bg-slate-800 text-white' : 'bg-white'}`}>
            <h4 className='mr-3'>Made With Love By</h4>
            <Logo></Logo>
            <h4 className='ml-3'>Tazrian Ibne Amin</h4>
        </div>
    );
};

export default Footer;